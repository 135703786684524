import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getTransactions(params: any = {}): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'transactions', { params, headers: this.httpHeaders });
  }

  getCanExportTransactions(params: any = {}): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'transactions/canExport', { params: {
      filter: JSON.stringify(params),
    }, headers: this.httpHeaders });
  }

  exportTransactions(params: any = {}): Observable<any> {
    return this.http.get(environment.ApiUrl + 'transactions/export', {
      params: {
        filter: JSON.stringify(params),
      },
      headers: this.httpHeaders,
      responseType: 'blob',
    });
  }

  getTransaction(transactionId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'transaction/' + transactionId, { headers: this.httpHeaders });
  }

  getTransactionsKPI(getParams): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'transactions/kpi?' + getParams, { headers: this.httpHeaders });
  }

  postTransactionStatus(params): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'transaction/status', params, { headers: this.httpHeaders });
  }

  getTransactionStatus(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'transaction/status', { headers: this.httpHeaders });
  }
}
